<template>
  <div class="row mx-0 under_curve">
    <div class="shape">
      <div class="the_curve offwhite_bg"></div>
    </div>
    <div class="col-12 p-0 h-100 ">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.under_curve {
  position: absolute;
  background-color: transparent;
  margin-top: -202px;
  height: calc(100vh - 133px);
  z-index: 1;
  left: 0;
  right: 0;
}
.shape {
  overflow: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  bottom: -1px;
}
.shape .the_curve {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  width: calc(135% + 1.3px);
  height: 300px;
  display: block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}
.offwhite_bg {
  background-color: rgb(249, 250, 251);
}
</style>
